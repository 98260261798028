import api from "../baseurl";

const showAll = async () => {
    
    const CompaniesShow = await api.get("companies/showall").json();
    //console.log(CompaniesShow.data);
    return CompaniesShow.data;
    
  };
  
  export default  showAll ;