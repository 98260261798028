import api from "../baseurl";

const Show = async (rfc) => {
    
  //console.log(rfc)
    const status = await api.get("user-profiles/show/"+ rfc).json();
    //console.log(status)
    return status ;
    
  };
  
  export default Show ; 
