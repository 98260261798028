import React from 'react';
import PropTypes from 'prop-types';
import {List, ListItem, ListItemText, Grid, Typography, FormControl, Select, MenuItem, InputLabel, TextField} from "@material-ui/core"
//import Radio from '@material-ui/core/Radio';
import { withStyles} from '@material-ui/core/styles';
import GetEmployeeInfo from '../../api/tickets/employee'
import GetCompanies from '../../api/tickets/getCompanies'

const styles = theme =>({
    formControl: {
      //margin: theme.spacing(0),
      margin: theme.spacing(1),
    },
    title: {
      fontSize: 14,
    },
    margin: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    },
    ListItemText: {
        textAlign: 'center',    
    },
    inputCompanie:{
        //width: '50%',
        margin: theme.spacing(1),
        minWidth: 200,

    }
  });


export class EmployeeDetails extends React.Component{
    constructor(props){
        super(props);
        this.state= {
            name: '',
            company: '',
            active: '',
            companies: '',
            companyUuid: '',
        }
    }
async componentDidMount(){
        this.props.manualChange({var: 'companyUuid', value:''})
        if(this.props.values.rfc_name && this.props.values.employeeInfo===''){
        const employee = await GetEmployeeInfo(this.props.values.rfc)
        //console.log(employee)
            if (employee.data!==null){
                if (employee.data.User.Employees!==null){
                this.props.manualChange({var: 'employeeInfo', value: employee.data})
                this.props.manualChange({var: 'companyUuid', value: (employee.data.User.Employees!==null)?employee.data.User.Employees[0].Company.uuid: ''})
                this.setState({
                    name: [employee.data.name, employee.data.lastNames].join(' '),
                    company: (employee.data.User.Employees!==null)?employee.data.User.Employees[0].Company.name: 'No se encontro registro',
                    active:  (employee.data.User.Employees[0])? 'Activo': 'No Activo'
                })}else{
                this.props.manualChange({var: 'employeeInfo', value: ''})
                this.props.manualChange({var: 'messageAlert', value: 'No se encontro informacion referente a su empresa'})
                this.props.manualChange({var: 'activeStep', value:0})
                }

            }else
            {
                this.props.manualChange({var: 'employeeInfo', value: ''})
                this.props.manualChange({var: 'messageAlert', value: 'No se encontro informacion referente a su RFC'})
                this.props.manualChange({var: 'activeStep', value:0})
                
            }
        }else if(this.props.values.employeeInfo!==''){//this.props.values.rfc_name){
            this.setState({
                name: [this.props.values.employeeInfo.name, this.props.values.employeeInfo.lastNames].join(' '),
                company:this.props.values.employeeInfo.User.Employees[0].Company.name,
                active:  (this.props.values.employeeInfo.User.Employees[0])? 'Activo': 'No Activo'
            })
            //console.log(this.props.value)
            this.props.manualChange({var: 'companyUuid', value: (this.props.values.employeeInfo.User.Employees!==null)?this.props.values.employeeInfo.User.Employees[0].Company.uuid: ''})
        }else{
            const companies = await GetCompanies();
            this.setState({
                companies,
                companyUuid: this.props.values.companyUuid
            })
            //console.log(this.props.values.companyUuid)
        }

  }
    render() {
      const { classes, values, handleChange } = this.props;
      //const {  } = this.props; 
      if(!values){
          return null
      }
        return(
            <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
           {/*    <RadioGroup defaultValue={values.boolrfc_name} aria-label="rfc" className={classes.formControl} onChange={handleChange('boolrfc_name')}>
                  <FormControlLabel
                  value= 'RFC'
                  control={<Radio color="primary" />}
                  label="Con RFC"
                  //labelPlacement="start" 
                  />
                  <FormControlLabel
                  value= 'SinRFC'
                  control={<Radio color="primary" />}
                  label="Con Nombre"
                  //labelPlacement="start"
                  />
              </RadioGroup> */}
              
              <Typography variant='h6'>Confirmacion de datos</Typography>
              {(values.rfc_name)?
              (<List>
                  <ListItem>
                  <ListItemText  
                    className={classes.ListItemText}
                    primary={this.state.name}
                    secondary='Nombre' />
                  </ListItem>
                  <ListItem>
                  <ListItemText  
                    className={classes.ListItemText}
                    primary={this.state.company}
                    secondary='Empresa' />
                  </ListItem>
                  <ListItem>    
                  <ListItemText  
                    className={classes.ListItemText}
                    primary={this.state.active}
                    secondary='Status' />
                  </ListItem>
              </List>): (
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center">
                        {this.state.companies.length > 0 ? ( 
                        <FormControl variant="outlined" className={classes.inputCompanie}>
                        <InputLabel id="demo-simple-select-outlined-label">Seleccione su Empresa</InputLabel>
                        <Select
                        labelId="demo-simple-select-outlined-label"
                         id="demo-simple-select-outlined"
                        value={this.state.companyUuid}
                        defaultValue={''}
                        //name='CompanyUuid'
                        onChange={handleChange('companyUuid')}
                        labelWidth= {169}
                        >
                    {this.state.companies.map((company,index) => {
                        return (
                            <MenuItem value={company.uuid} key={company.uuid}>{company.name}</MenuItem>
                        );
                    })}
                    </Select>
                    </FormControl>
                    ) : (                              
                    <FormControl variant="outlined" className={classes.inputCompanie}>
                        <InputLabel id="demo-simple-select-outlined-label">Seleccione su Empresa</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            labelWidth= {169}
                            >
                            <MenuItem value={0}>_</MenuItem>
                            </Select>
                    </FormControl>
                     )}
                    <TextField 
              className={classes.margin} 
              id="outlined-basic" variant="outlined" 
              label='Ingrese su Nombre' 
              onChange={handleChange('name')}
              defaultValue={values.name}
              />
                </Grid>
              )}
              <Typography>Si sus datos estan correctos de clic en continuar</Typography>
            </Grid>
        )
    }
}

EmployeeDetails.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles)(EmployeeDetails)